import logo from './logo.svg';
import './App.css';
import { NavBarHeader, SideBar , MarketingFooterBrand} from './ui-components';
import Workspace  from './Workspace';
import {Grid, View } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useState } from 'react';


function App() {
  const [menuItem, setMenuItem] = useState('HomeMenu');
  
  return (
    <div className="App">
      <NavBarHeader />
      <View className='bodyclass'>
        <SideBar className='sidebar' overrides= {{
          HomeMenu    : {onClick: () => setMenuItem('DashboardMenu')},
          UsersMenu   : {onClick: () => setMenuItem('UsersMenu')},
          GroupsMenu  : {onClick: () => setMenuItem('GroupsMenu')},
          RolesMenu   : {onClick: () => setMenuItem('RolesMenu')},
          PermissionsMenu   : {onClick: () => setMenuItem('PermissonsMenu')},
          WorkflowsMenu   : {onClick: () => setMenuItem('WorkflowsMenu')},
          SitesMenu  : {onClick: () => setMenuItem('SitesMenu')},
          JobsMenu   : {onClick: () => setMenuItem('JobsMenu')},
          HistoryMenu   : {onClick: () => setMenuItem('HistoryMenu')},
          CleanupMenu   : {onClick: () => setMenuItem('CleanupMenu')},
          LogsMenu   : {onClick: () => setMenuItem('LogsMenu')},
          IssuesMenu   : {onClick: () => setMenuItem('IssuesMenu')},
          TaggingMenu  : {onClick: () => setMenuItem('TaggingMenu')},
          AnalyticsMenu   : {onClick: () => setMenuItem('AnalyticsMenu')},
        }}/>
        <Workspace className="workspace" menuItem={menuItem}/>
      </View>
      <MarketingFooterBrand className='footer' />
    </div>
  );
}

export default withAuthenticator(App);
