

class  DBCache {
  static initialized = false;
  static siteList = null;

  constructor() {
    DBCache.initialized = true;
  }

  whatsMyName(){
    return (DBCache.initialized ?  "Heisenberg" : "Walt");
  }

  getSiteList(callbackFn, force=false) {
    if (DBCache.siteList && !force) {
      callbackFn(DBCache.siteList);
    }
    else
    {
      const xhr = new XMLHttpRequest();
      function setSiteData() {
        if (xhr.status >= 200 && xhr.status < 300) {
          DBCache.siteList = JSON.parse(xhr.responseText).Items
          callbackFn(DBCache.siteList);  
        }
        else {
          console.error("Request failed with status :", xhr.status);
          // Nothing more to do...
        }
      }

      xhr.onload = setSiteData;
      xhr.open("POST","https://console.hajime.hyozan.com/default/hajime-console-handler",true);
      const reqBody = {
        command  : 'getSites',
        userInfo : {id: "dummy", token: "98jewdkjh9874239rhskjhnlkag9p"} // TODO : Need to send the correct tokens..
      };

      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(reqBody));
    }
  }
}

export default DBCache;



