import {Button, Grid, Label, View } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

import  DBCache  from './DBCache'; 

/*** Dummy data for now */
const dummySiteList = [
    {
        siteName: "hyozan.com",
        siteDescription: "Public website for Hyozan Solutions Pte Ltd",
        siteEnabled: true,
        lastModifiedDate: Date("2024-02-20T13:21:15Z"),
        createdDate: Date("2023-12-20T10:15:22Z"),
    },
    {
        siteName: "modern.hyozan.com",
        siteDescription: "Demo website for Hajime Modern Look",
        siteEnabled: true,
        lastModifiedDate: Date("2024-03-15T13:21:15Z"),
        createdDate: Date("2024-02-20T10:15:22Z"),        
    }
];

var fetchedSiteList = false;


export default function SiteManager(){
    
    const [showAddSite, setShowAddSite] = useState(false);
    const [siteList, setSiteList] = useState(null);
   
    const db = new DBCache();

    useEffect(dbFetchSiteList,[]);

    function dbFetchSiteList() {
        db.getSiteList(setSiteList);
    }

    function AddSiteForm(){
        const [formData, setFormData] = useState({sitename:'', description:''});

        const handleChange = (e) => {
            const {name, value } = e.target;
            setFormData({...formData, [name]:value});
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            window.alert("Now I will invoke the site adder with site: " + 
                                formData.sitename + 
                                "and description : " + 
                                formData.description);
            setFormData({sitename:'', description:''}); // reset values after submission
        }
        const handleCancel = (e) => {
            setFormData({sitename:'', description:''}); // reset values after submission
            setShowAddSite(false);
        }

        return (
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="sitename">Site Name:</label>
                    <input
                        type="text"
                        id="sitename"
                        name="sitename"
                        value={formData.sitename}
                        onChange={handleChange}
                        required
                        />
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    />
                </div>
                <div>
                    <button type="submit">Submit</button>
                    <button type="button" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        );
    }

    const setShowAddSiteTrue = () => {setShowAddSite(true)};
    const refreshSiteList = () => {db.getSiteList(setSiteList, true)}; // Force a refresh

    let i=1;

    return(
        <View>

            <h1> {db.whatsMyName()}</h1>
            <Button onClick={setShowAddSiteTrue}> Add Site</Button>

            <h1> List of sites </h1>            
            <ul>
                {siteList ? 
                    siteList.map((site) => {return(
                        <li key={i++}>
                            { site.siteName + " : " + site.siteStatus + " : " + site.siteDescription + " : " + site.siteCreated + " : " + site.siteDistributionDomain }
                        </li>
                    )}) 
                    : true}
            </ul>
            <button onClick={refreshSiteList}>refresh</button>

            {showAddSite ? 
                <View>
                    <br />
                    <br />
                    This will be where we add the site <br />
                    With information required being: <br />

                    <AddSiteForm />

                </View>
                    
            :<p></p>}
        </View>
    )
}