/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Vector(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M19.9999 4.34545L15.0136 1.91574L13.2297 2.78503L9.97262 0L6.73544 2.76805L4.9863 1.91574L4.54747e-13 4.34545L19.9999 4.34545Z",
          fill: "rgba(9,69,129,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M9.9726 20L13.1233 11.7708L15.0137 13.4865L20 5.22546L0 5.22546L4.9863 13.4865L6.76712 11.7708L9.9726 20Z",
          fill: "rgba(9,69,129,1)",
          fillRule: "evenodd",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Vector")}
      {...rest}
    ></Icon>
  );
}
