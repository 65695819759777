import {Grid, View } from '@aws-amplify/ui-react';
import SiteManager from './SiteManager';

function Workspace({menuItem}) {
    switch(menuItem){
        case 'DashboardMenu' :
        case 'GroupsMenu'    :   
        case 'RolesMenu'     :   
        case 'PermissonsMenu':
        case 'WorkflowsMenu' :
        case 'UsersMenu'     :   
        case 'JobsMenu'      :   
        case 'HistoryMenu'   :   
        case 'CleanupMenu'   :   
        case 'LogsMenu'      :   
        case 'IssuesMenu'    :   
        case 'TaggingMenu'   :   
        case 'AnalyticsMenu' : {                 
            return (
                    <View>
                        This is the workspace for {menuItem}
                    </View>
                );

            }
            break;
        case 'SitesMenu'     :   

            return(
                    <SiteManager />
                );
            break;

    }
}

export default Workspace;